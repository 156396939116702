import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const StyledLoaderContainer = styled(motion.div)`
  position: relative;
  height: 60px;
  width: 60px;
`;

export const StyledCircle = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.total};
  position: absolute;
  background-color: ${({ color, colorType, theme }) =>
    theme.colors.bg[color][colorType]};
  ${({ right, left, bottom, top, size }) =>
    css`
      left: ${left}%;
      right: ${right}%;
      top: ${top}%;
      bottom: ${bottom}%;
      height: ${size}px;
      width: ${size}px;
    `}
`;

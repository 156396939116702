import PropTypes from 'prop-types';

import { colorTypeProps } from '@config/common-propTypes';
import { StyledCircle, StyledLoaderContainer } from './Loader.styled';

const circles = [
  { left: 40, top: 0, right: 36, bottom: 76, size: 15 },
  { left: 13, top: 13, right: 65, bottom: 66, size: 13 },
  { left: 2.5, top: 43, right: 79, bottom: 38, size: 11 },
  { left: 15.5, top: 72, right: 68, bottom: 11, size: 9 },
  { left: 45, top: 86, right: 40.5, bottom: 0, size: 8.5 },
  { left: 72, top: 75, right: 13, bottom: 13, size: 7 },
  { left: 88, top: 47.5, right: 2, bottom: 43, size: 5.5 },
  { left: 77.5, top: 20, right: 15.5, bottom: 73, size: 4 }
];

const Loader = ({ color, colorType, duration }) => (
  <StyledLoaderContainer
    animate={{ rotate: 360 }}
    transition={{
      ease: 'linear',
      duration,
      repeatDelay: 0,
      loop: Infinity
    }}
  >
    {circles.map((circle) => (
      <StyledCircle
        key={circle.left + circle.size}
        color={color}
        colorType={colorType}
        {...circle}
      />
    ))}
  </StyledLoaderContainer>
);

Loader.propTypes = {
  color: PropTypes.string,
  colorType: colorTypeProps,
  duration: PropTypes.number
};

Loader.defaultProps = {
  color: 'primary',
  colorType: 'normal',
  duration: 0.8
};

export default Loader;
